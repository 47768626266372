import React from 'react';
import PropTypes from 'prop-types';

export default function Projects({ children }) {
	return <main className="projects">{children}</main>;
}

Projects.propTypes = {
	children: PropTypes.array,
};
