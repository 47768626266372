import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Projects from '../components/projects';
import Project from '../components/layout/project';
import SEO from '../components/seo';
import settingsData from '../settings/settings.json';

export default function Home({ data }) {
	const { siteTitle, seoDescription } = settingsData;

	return (
		<>
			<SEO title={siteTitle} description={seoDescription} />
			<Projects>
				{data.allMarkdownRemark.edges.map(({ node }) => (
					<Project key={node.id} payload={node} />
				))}
			</Projects>
		</>
	);
}

export const query = graphql`
	query {
		allMarkdownRemark(
			sort: { fields: [frontmatter___order], order: ASC }
			filter: { frontmatter: { draft: { ne: true } } }
		) {
			edges {
				node {
					id
					frontmatter {
						title
						thumbnail {
							offset
							image
							image_retina
							alt
							scale
						}
						category
						client
					}
					fields {
						slug
					}
				}
			}
		}
	}
`;

Home.propTypes = {
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			edges: PropTypes.arrayOf(PropTypes.object),
		}),
	}),
};
