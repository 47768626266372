import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCategory = styled.section`
	justify-self: end;
	align-self: end;
	transform: translateX(-3px);

	@media (min-width: 64em) {
		grid-column: 3 / 4;
		grid-row: 1 / 3;
		align-self: start;
	}
`;

const Category = (props) => {
	const { category } = props;
	if (category) {
		return <StyledCategory>{category}</StyledCategory>;
	}
	return null;
};

Category.propTypes = {
	category: PropTypes.string,
};

export default Category;
