import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledClient = styled.section`
	justify-self: start;
	align-self: start;
	margin-top: -1px;
	margin-left: 2px;

	@media (min-width: 64em) {
		grid-column: 1 / 2;
		grid-row: 2 / 3;
	}
`;

const Client = (props) => {
	const { client } = props;
	if (client) {
		return <StyledClient className="client">{client}</StyledClient>;
	}
	return null;
};

Client.propTypes = {
	client: PropTypes.string,
};

export default Client;
