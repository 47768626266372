import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { transformUrl } from '../../../util/util';

const StyledThumbnail = styled.section`
	justify-self: center;

	@media (max-width: 26.9375em) {
		align-self: center;
		padding: 25px 11vw 34px;
	}

	@media (min-width: 27em) and (max-width: 63.9375em) {
		align-self: center;
		padding: 30px 0;
	}

	@media (min-width: 64em) {
		grid-column: 2 / 3;
		grid-row: 1 / 3;
		align-self: start;
	}

	@media (min-width: 90em) {
		${(props) =>
			props.offset ? `transform: translateX(${props.offset}%);` : ''}
	}

	img {
		width: 100%;
		height: auto;
		display: block;
	}

	a:hover img,
	a:focus img {
		filter: invert(100%);
	}
`;

const Thumbnail = (props) => {
	const { thumbnail, retinaThumbnail, slug, offset, scale, alt } = props;

	const optimizedThumbnail = transformUrl(
		thumbnail,
		'upload',
		'f_auto,q_auto:good,cs_srgb'
	);
	const optimizedRetinaThumbnail = retinaThumbnail
		? transformUrl(retinaThumbnail, 'upload', 'f_auto,q_auto:low,cs_srgb')
		: null;

	if (thumbnail) {
		return (
			<StyledThumbnail offset={offset}>
				<Link to={slug}>
					<img
						src={optimizedThumbnail || thumbnail}
						srcSet={
							optimizedRetinaThumbnail
								? `${optimizedRetinaThumbnail} ${scale}`
								: null
						}
						alt={alt || thumbnail}
					/>
				</Link>
			</StyledThumbnail>
		);
	}
	return null;
};

Thumbnail.propTypes = {
	thumbnail: PropTypes.string,
	retinaThumbnail: PropTypes.string,
	alt: PropTypes.string,
	scale: PropTypes.string,
	offset: PropTypes.number,
	slug: PropTypes.string,
};

export default Thumbnail;
