import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledTitle = styled.h2`
	justify-self: start;
	align-self: start;
	transform: translateX(2px);

	@media (max-width: 26.9375em) {
		margin-bottom: 2px;
	}

	@media (min-width: 64em) {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		align-self: start;
	}
`;

const Title = (props) => {
	const { title, slug } = props;
	if (title) {
		return (
			<StyledTitle>
				<Link to={slug}>{title}</Link>
			</StyledTitle>
		);
	}
	return null;
};

Title.propTypes = {
	title: PropTypes.string,
	slug: PropTypes.string,
};

export default Title;
