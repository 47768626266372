import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Title from './project/title';
import Client from './project/client';
import Category from './project/category';
import Thumbnail from './project/thumbnail';

const StyledProject = styled.article`
	position: relative;
	display: grid;

	@media (max-width: 26.9375em) {
		padding: 6px 0 9px;
	}

	@media (min-width: 27em) {
		padding: 9px 0;
	}

	@media (max-width: 26.9375em) {
		font-size: calc(21rem / 16);
	}

	@media (min-width: 27em) and (max-width: 29.9375em) {
		font-size: calc(24rem / 16);
	}

	@media (min-width: 30em) and (max-width: 63.9375em) {
		font-size: calc(30rem / 16);
	}

	@media (min-width: 64em) {
		font-size: calc(38rem / 16);
		grid-template-columns: auto 1fr auto;
		grid-template-rows: auto 1fr;
		grid-column-gap: 0.5rem;
	}

	&::after,
	&:first-of-type::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 2px;
		left: 0;
		background-color: black;
	}

	&::after {
		bottom: 0;
	}

	&:first-of-type::before {
		top: 0;
	}
`;

const Project = (props) => {
	const { payload } = props;
	if (payload) {
		return (
			<StyledProject className="project">
				<Title
					title={payload.frontmatter.title}
					slug={payload.fields.slug}
				/>
				<Client client={payload.frontmatter.client} />
				{payload.frontmatter.thumbnail && (
					<Thumbnail
						thumbnail={payload.frontmatter.thumbnail.image}
						retinaThumbnail={
							payload.frontmatter.thumbnail.image_retina
						}
						alt={payload.frontmatter.thumbnail.alt}
						scale={payload.frontmatter.thumbnail.scale}
						offset={payload.frontmatter.thumbnail.offset}
						slug={payload.fields.slug}
					/>
				)}
				<Category category={payload.frontmatter.category} />
			</StyledProject>
		);
	}
	return null;
};

Project.propTypes = {
	payload: PropTypes.shape({
		frontmatter: PropTypes.shape({
			title: PropTypes.string,
			client: PropTypes.string,
			thumbnail: PropTypes.shape({
				image: PropTypes.string,
				image_retina: PropTypes.string,
				alt: PropTypes.string,
				scale: PropTypes.string,
				offset: PropTypes.number,
			}),
			category: PropTypes.string,
		}),
		fields: PropTypes.shape({
			slug: PropTypes.string,
		}),
	}),
};

export default Project;
